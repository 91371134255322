import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ChJpX0LT3B8">
    <SEO title="Which King Will You Follow? - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
